import { getAuth, signOut } from "firebase/auth";
import Header from "../components/header";
import React, { useEffect, useState } from "react";
import useWindowSize from "../util/windowSizeState";
import { DURCON_GREEN, MOBILE_BREAKPOINT } from "../constants";
import AdminDashboard from "../components/adminDashboard";
import AdminAnalytics from "../components/adminAnalytics";
import AdminGalleryManagement from "../components/adminGalleryManagement";
import AdminAccount from "../components/adminAccount";

function Dashboard() {
  const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;
    const height = useWindowSize().height;

    const [navSelection, setNavSelection] = useState(0);

    const navButtons = ['Dashboard', 'Analytics', 'Blog Management', 'Gallery Management', 'Account'];


    useEffect(() => {
        document.title = 'Durcon Electric Inc. - Dashboard';
    }, []);


    const styles: {[key: string]: React.CSSProperties} = {
        layoutContainer: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
        },
        navPanel: {
            display: 'flex',
            flexDirection: 'column',
            height: isMobile ? 'auto' : height,
            backgroundColor: '#333',

            paddingTop: '3rem',
            paddingBottom: '3rem',
        },
        navButton: {
            color: 'white',
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: isMobile ? 'x-large' : 'medium',
            borderRadius: 0,
        },
        contentPanel: {
            padding: isMobile ? 0 : '3rem',
        }
    }

    return (
        <div>
            <Header />
            
            {/* Page layout container */}
            <div style={styles.layoutContainer}>

                {/* Nav panel */}
                <div style={styles.navPanel}>
                    {navButtons.map((button, index) => (
                    <button
                        key={index}
                        style={{...styles.navButton, backgroundColor: navSelection === index ? DURCON_GREEN : 'rgba(0, 0, 0, 0)'}}
                        onClick={() => setNavSelection(index)}
                        className="btn btn-default"
                    >
                        {button}
                    </button>
                    ))}
                </div>

                {/* Content panel */}
                <div style={styles.contentPanel}>
                    {
                        navSelection==0 && <AdminDashboard />
                    }
                    {
                        navSelection==1 && <AdminAnalytics />
                    }
                    {
                        navSelection==2
                    }
                    {
                        navSelection==3 && <AdminGalleryManagement />
                    }
                    {
                        navSelection==4 && <AdminAccount />
                    }
                </div>

            </div>

        </div>
    );
}

export default Dashboard;