import React from 'react';
import galleryImage0 from '../images/IMG_2660.png';
import galleryImage1 from '../images/IMG_1597.png';
import galleryImage2 from '../images/IMG_3904.png';
import useWindowSize from '../util/windowSizeState';
import { MOBILE_BREAKPOINT } from '../constants';
import { Link } from 'react-router-dom';

const styles: {[key: string]: React.CSSProperties} = {
    sectionContainer: {
        margin: '3rem',
    },
    galleryContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2rem',
        flexWrap: 'wrap',
    },
    imageContainer: {
        maxWidth: '250px',
        // flex: '1 0 auto', // This makes the image containers take up an equal amount of space, but not beyond their maxWidth
        position: 'relative', // This allows the image to be positioned within the container
        borderRadius: '10px',
        boxShadow: '0px 2px 10px 2px #ddd',
        margin: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageContainerBefore: {
        content: '""',
        display: 'block',
        paddingTop: '100%', // This creates a box that's as tall as it is wide
    },
    image: {
        objectFit: 'cover',
        position: 'absolute', // This positions the image within the container
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // filter: 'blur(0.25px)',
        borderRadius: '10px',
    },
    horizontalSeparator: {
        width: '2rem',
    },
    viewMoreButton: {
        backgroundColor: '#3C7E83',
        color: 'white',
        width: '16rem',
    }
}

function HomeMediaGallery() {
    const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;
    const desktopVW = '70vw';
    const mobileVW = '30vw';

    return (
        <div style={styles.sectionContainer}>
            <h1>Media Gallery</h1>
            {/* <h4 style={{fontWeight: 'lighter'}}>Click to view full-sized images</h4> */}

            <div style={styles.galleryContainer}>
                <div style={{...styles.imageContainer, width: isMobile ? desktopVW : mobileVW}}>
                    <div style={styles.imageContainerBefore}></div>
                    <img src={galleryImage0} style={styles.image} alt="Gallery image 0" />
                </div>

                <div style={{...styles.imageContainer, width: isMobile ? desktopVW : mobileVW}}>
                    <div style={styles.imageContainerBefore}></div>
                    <img src={galleryImage1} style={styles.image} alt="Gallery image 1" />
                </div>

                <div style={{...styles.imageContainer, width: isMobile ? desktopVW : mobileVW}}>
                    <div style={styles.imageContainerBefore}></div>
                    <img src={galleryImage2} style={styles.image} alt="Gallery image 2" />
                </div>
            </div>

            <Link to={"/gallery"} className="no-underline">
                <p className='btn btn-lg' style={styles.viewMoreButton}>View More</p>
            </Link>
        </div>
    );
}

export default HomeMediaGallery;
