import React, { useEffect } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { DURCON_GREEN, MOBILE_BREAKPOINT } from "../constants";
import useWindowSize from "../util/windowSizeState";
import ContactInline from "../components/contactInline";
import HomeAssociations from "../components/home_associations";

function ESAPage() {
    const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;

    useEffect(() => {
        document.title = 'Durcon Electric Inc. - ESA';
    }, []);

    const styles: {[key:string]: React.CSSProperties} = {
        pageContentContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        topRowContainer: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem',
            width: '100%',
            maxWidth: '1200px',
        },
        pageTitle: {
            color: DURCON_GREEN,
            textAlign: isMobile ? 'center' : 'left',
            fontWeight: 'bold',
        },
        esaInfoBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            backgroundColor: DURCON_GREEN,
            borderRadius: '5px',
            padding: '1rem',
            width: isMobile ? '100%' : 'auto',
        },
        esaInfoBoxText: {
            color: 'white',
            fontSize: 'large',
            fontWeight: '500',
            padding: 0,
            margin: 0,
        },
        accordionContainer: {

        }
    }

    return (
        <div>
            <Header />
            <div style={styles.pageContentContainer}>
                
                {/* Top row */}
                <div style={styles.topRowContainer}>
                    
                    {/* Page title */}
                    <h1 style={styles.pageTitle}>Our Commitment to Electrical Safety Authority Standards</h1>
                    
                    {/* ESA number & link */}
                    <div style={styles.esaInfoBox}>
                        <h3 style={styles.esaInfoBoxText}>ESA License #7004995</h3>
                    </div>
                </div>
                {/* Accordion */}
                <div>
                    <ESAAccordion />
                </div>

                <HomeAssociations />

                {/* Contact inline */}
                <div style={{width: '100%', display: 'flex', justifyContent: "center", alignItems: "center"}}>
                    <div style={{maxWidth: '600px'}}>
                        <ContactInline />
                    </div>
                </div>
                
            </div>

            <Footer />
        </div>
    );
}

function ESAAccordion() {
    const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;

    const styles: {[key: string]: React.CSSProperties} = {
        accordionContainer: {
            width: isMobile ? '100vw' : MOBILE_BREAKPOINT,
            padding: '2rem',
        },
        accordionButton: {
            width: '100%',
        },
        accordionBodyText: {
            textAlign: 'left',
        },
        accordionBodyTextSpan: {
            color: DURCON_GREEN,
            fontWeight: 'bold',
        },
        accordionBodyTextList: {
            color: DURCON_GREEN,
        },
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
            {/* Accordion container */}
            <div style={styles.accordionContainer}>
                {/* Accordion */}
                <div className="accordion" id="accordion" style={{width: '100%'}}>
                    {/* Item 1 */}
                    <div className="accordion-item" style={{width: '100%'}}>
                        {/* Button 1 */}
                        <h2 className="accordion-header" id="headingOne" style={{width: '100%'}}>
                            <button style={styles.accordionButton} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                ESA Certification: What It Means
                            </button>
                        </h2>
                        {/* Body 1 */}
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <p style={styles.accordionBodyText}>
                                    ESA Certification is a testament to an electrical contractor’s adherence to the highest safety standards as set by the Electrical Safety Authority of Ontario. It signifies that the company has undergone rigorous inspections and complies with the Ontario Electrical Safety Code.
                                    <br /><br />
                                    <ul>
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Rigorous Standards:</span> The certification process involves a thorough evaluation of the company’s practices, ensuring they meet or exceed the safety standards.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Continuous Compliance:</span> Maintaining ESA Certification requires the company to stay up-to-date with the latest safety regulations and code amendments.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Professionalism:</span> ESA Certification reflects the company’s commitment to professional development and technical competence.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Public Trust:</span> It assures customers that the electrical work performed is safe, reliable, and of high quality.</li>
                                    </ul>
                                    <br />
                                    By choosing an ESA-certified electrician, clients can have peace of mind knowing that their electrical needs are handled by qualified professionals dedicated to safety and excellence.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Item 2 */}
                    <div className="accordion-item">
                        {/* Button 2 */}
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Our Journey to ESA Certification
                            </button>
                        </h2>
                        {/* Body 2 */}
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <p style={styles.accordionBodyText}>
                                Our journey began with a commitment to excellence. We understood that ESA certification would not only validate our expertise but also provide our clients with the assurance that their electrical needs are being handled by qualified professionals.

                                    <br /><br />
                                    <ol>
                                        <li style={styles.accordionBodyTextList}>The first step towards ESA certification involved extensive education and training. Our team participated in rigorous courses and workshops to deepen our understanding of electrical systems and safety protocols. This training equipped us with the knowledge and skills necessary to perform our work to the highest standard.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}>After completing our training, we underwent a comprehensive examination process. This involved both written and practical assessments designed to test our ability to apply our knowledge in real-world scenarios. We’re proud to say that our team excelled in these evaluations, demonstrating our proficiency and dedication to our craft.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}>Upon passing our examinations, we were thrilled to receive our ESA certification. This certification is a testament to our commitment to safety, quality, and professionalism. It serves as a guarantee to our clients that we adhere to the highest industry standards in all our operations.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}>Even after achieving our ESA certification, we continue to prioritize ongoing education and training. We believe in staying up-to-date with the latest industry developments and safety standards to provide our clients with the best service possible.</li>
                                    </ol>
                                    <br />
                                    We’re proud of our journey to ESA certification and the expertise it represents. When you choose us, you’re choosing a team of dedicated, ESA-certified professionals committed to delivering safe, high-quality electrical solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Item 3 */}
                    <div className="accordion-item">
                        {/* Button 3 */}
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Maintaining ESA Standards
                            </button>
                        </h2>
                        {/* Body 3 */}
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <p style={styles.accordionBodyText}>
                                We prioritize safety above all else. We are committed to maintaining the highest standards set by the Electrical Safety Authority (ESA). Our team of licensed electricians is well-versed in the latest ESA codes and regulations, ensuring that every project we undertake is compliant and safe.
                                    <br /><br />
                                    <ul>
                                        <li style={styles.accordionBodyTextList}>We understand that electrical work is not just about getting the job done; it’s about getting it done right. That’s why we adhere strictly to ESA standards in all our operations. From the planning stages to the final touches, we ensure that our work meets or exceeds these standards.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}>Our electricians regularly participate in training and education programs to stay updated with the latest ESA standards and best practices. This commitment to continuous learning ensures that we are always at the forefront of electrical safety.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}>We conduct regular audits and inspections to verify that our work aligns with ESA standards. Our quality assurance process includes thorough checks and balances to ensure that every project we complete is up to code.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}>By adhering to ESA standards, we aim to provide our clients with the peace of mind that their electrical systems are safe, reliable, and efficient. Your safety is our priority, and we are dedicated to providing electrical services that you can trust.</li>
                                    </ul>
                                    <br />
                                    Remember, when it comes to electrical work, never compromise on safety. Choose an electrical contractor who respects and upholds ESA standards.
                                </p>
                            </div>
                        </div>
                        {/* Body 3 */}
                    </div>
                    {/* Item 4 */}
                    <div className="accordion-item">
                        {/* Button 4 */}
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Benefits of Choosing an ESA Certified Company
                            </button>
                        </h2>
                        {/* Body 4 */}
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <p style={styles.accordionBodyText}>
                                When you need electrical work done in your home or business, you want to make sure it is done safely, correctly, and legally. That’s why you should always choose an ESA certified contractor. By choosing an ESA certified contractor, you can enjoy the following benefits:
                                    <br /><br />
                                    <ul>
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Peace of mind:</span> You can trust that your electrical work is done by qualified professionals who follow the best practices and safety protocols.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Quality assurance:</span> You can expect high-quality workmanship and materials that meet or exceed the industry standards.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Compliance:</span> You can avoid potential fines, penalties, or legal issues that may arise from non-compliant electrical work.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Warranty:</span> You can benefit from the warranty program that covers defects in materials and workmanship for up to one year after the completion of the work.</li>
                                        <br />
                                        <li style={styles.accordionBodyTextList}><span style={styles.accordionBodyTextSpan}>Protection:</span> You can rely on the ESA to inspect and approve your electrical work, and to provide support and assistance in case of any issues or disputes.</li>
                                    </ul>
                                    <br />
                                    Don’t take chances with your electrical work. Choose an ESA certified contractor and enjoy the benefits of electrical safety and quality. We are proud to be an ESA certified contractor.
                                </p>
                            </div>
                        </div>
                        {/* Body 4 */}
                    </div>
                    {/* Item 4 */}
                </div>
                {/* Accordion */}
            </div>
            {/* Accordion container */}
        </div>
    );
}

export default ESAPage;