import React, { useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { DURCON_GREEN } from "../constants";
import phoneIcon from '../images/phone-call.png';
import emailIcon from '../images/email.png';
import successIcon from '../images/success.png';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

const styles: {[key: string]: React.CSSProperties} = {
    lineContactUs: {
        color: DURCON_GREEN,
        fontSize: 'large',
    },
    lineGetInTouch: {
        fontWeight: 'bold',
    },
    lineWedLove: {
        fontSize: 'large',
        fontWeight: 'lighter',
    },
    contactInfoText: {
        color: "black",
        fontSize: 'x-large',
        fontWeight: 'bold',
        padding: '1rem',
    },
}

function ContactPage() {
    const [submitted, setSubmitted] = useState(false);

    return(
        <div>
            <Header />
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", paddingTop: '3rem'}}>
                
                {
                    !submitted &&
                    <div>
                        <h2 style={styles.lineContactUs}>Contact Us</h2>
                        <h1 style={styles.lineGetInTouch}>Get in touch</h1>
                        <h3 style={styles.lineWedLove}>We'd love to hear from you. Please fill out this form.</h3>
                        <FormComponent setSubmitted={setSubmitted} />
                    </div>
                }
                {
                    submitted &&
                    <SuccessComponent />
                }
            </div>

            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{maxWidth: '60vw'}}>
                    <h2 style={{fontWeight: 400}}>You can also reach us directly using the following methods</h2>
                    <hr />
                    
                    {/* Phone */}
                    <div>
                        <div style={{display: "flex", flexDirection: 'row', justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap'}}>
                            <img src={phoneIcon} width={50} alt="Phone" />
                            <a style={styles.contactInfoText} href="tel:+19057611834">905-761-1834</a>
                        </div>
                    </div>

                    {/* Email */}
                    <div>
                        <div style={{display: "flex", flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <img src={emailIcon} width={50} alt="Email" />
                            <a style={styles.contactInfoText} href="mailto:info@durconelectric.com">info@durconelectric.com</a>
                        </div>
                    </div>

                    <hr />
                    <div style={{height: '4rem'}}></div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

const formStyles: {[key: string]: React.CSSProperties} = {
    form: {
        margin: '2rem',
    },
    entryFlexbox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginBottom: '1rem',
    },
    sendButton: {
        width: '100%',
        color: 'white',
        backgroundColor: DURCON_GREEN,
        marginBottom: '1rem',
    }
}

function FormComponent({setSubmitted}: {setSubmitted: React.Dispatch<React.SetStateAction<boolean>>}) {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const firestore = getFirestore();

        try {
            const docRef = await addDoc(collection(firestore, 'contact-submissions'), {
                firstname: formData.firstname,
                lastname: formData.lastname,
                email: formData.email,
                phone: formData.phone,
                message: formData.message,
                timestamp: serverTimestamp(),
            });
            setFormData({
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                message: '',
            });
            setSubmitted(true);
        } catch (e) {
            // console.log(e);
        }
    }

    return (
        <form style={formStyles.form} onSubmit={handleSubmit}>
            
            {/* Name */}
            <div style={{display: 'flex'}}>
                
                {/* First name */}
                <div style={formStyles.entryFlexbox}>
                    <label htmlFor="firstname-input" className="form-label">First name</label>
                    <input type="text" id="firstname-input" name="firstname" value={formData.firstname} onChange={handleChange} className="form-control" placeholder="First name" required />
                </div>

                {/* Separator */}
                <div style={{width: '1rem'}}></div>

                {/* Last name */}
                <div style={formStyles.entryFlexbox}>
                    <label htmlFor="lastname-input" className="form-label">Last name</label>
                    <input type="text" id="lastname-input" name="lastname" value={formData.lastname} onChange={handleChange} className="form-control" placeholder="Last name" required />
                </div>

            </div>

            {/* Email */}
            <div style={formStyles.entryFlexbox}>
                <label htmlFor="email-input" className="form-label">Email</label>
                <input type="email" id="email-input" name="email" value={formData.email} onChange={handleChange} className="form-control" placeholder="you@company.com" required />
            </div>

            {/* Phone number */}
            <div style={formStyles.entryFlexbox}>
                <label htmlFor="phone-input" className="form-label">Phone number <span style={{fontWeight: 'lighter'}}>(optional)</span></label>
                <input type="number" id="phone-input" name="phone" value={formData.phone} onChange={handleChange} className="form-control" placeholder="+1 (555) 000-0000" />
            </div>

            {/* Message body */}
            <div style={formStyles.entryFlexbox}>
                <label htmlFor="message-input" className="form-label">Message</label>
                <textarea id="message-input" name="message" value={formData.message} onChange={handleChange} className="form-control" cols={30} rows={10}></textarea>
            </div>

            {/* Send button */}
            <button className="btn btn-lg" style={formStyles.sendButton}>Send</button>

        </form>
    );
};

function SuccessComponent() {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: '3rem', paddingBottom: '3rem'}}>
            <h1>Success</h1>
            <img src={successIcon} width={125} alt="Success" />
            <p style={{fontSize: 'larger'}}>Message sent</p>
        </div>
    );
}

export default ContactPage;