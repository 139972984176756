import React, { useEffect } from 'react';
import { DURCON_GREEN, MOBILE_BREAKPOINT } from '../constants';
import bellIcon from '../images/bell.png';
import checkIcon from '../images/check.png';
import gearsIcon from '../images/gears.png';
import useWindowSize from '../util/windowSizeState';
import Header from '../components/header';
import Footer from '../components/footer';
import ContactInline from '../components/contactInline';

function FireProtectionServicesPage() {
    const isMobile = useWindowSize().width < 800;

    useEffect(() => {
        document.title = 'Durcon Electric Inc. - DFPS';
    }, []);

    const styles: {[key: string]: React.CSSProperties} = {
        infoBitBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            maxWidth: '300px',
            padding: '1rem',
        },
        infoBitTitle: {
            textAlign: 'left',
            height: isMobile ? 'auto' : '80px',
        },
        infoBitText: {
            textAlign: 'left',
        },
        verticalSeparator: {
            width: '1px',
            height: '200px',
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: '1rem',
            marginRight: '1rem',
            backgroundColor: '#ddd',
            borderRadius: '1px',
        },
        horizontalSeparator: {
            width: '100%',
            height: '1px',
            margin: 'auto',
            backgroundColor: '#ddd',
            borderRadius: '1px',
        },
    }

    return (
        <div>
            <Header />

            {/* Page content */}
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '3rem'}}>
                
                {/* Page title */}
                <h1 style={{color: DURCON_GREEN, fontWeight: 'bold'}}>Durcon Fire Protection Services Inc.</h1>
                
                {/* Info bits */}
                <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row'}}>
                    
                    {/* Installation */}
                    <div style={styles.infoBitBox}>
                        <img src={bellIcon} width={100} alt="Bell icon" />
                        <h3 style={styles.infoBitTitle}>Fire Alarm Installation</h3>
                        <p style={styles.infoBitText}>Trust our skilled team for the precise electrical installation of your fire alarm system. We handle all electrical connections, ensuring a seamless integration with your existing infrastructure.</p>
                    </div>

                    {/* Separator */}
                    <div style={isMobile ? styles.horizontalSeparator : styles.verticalSeparator}></div>
                    
                    {/* Verification */}
                    <div style={styles.infoBitBox}>
                        <img src={checkIcon} width={100} alt="Checkmark icon" />
                        <h3 style={styles.infoBitTitle}>Fire Alarm Verification</h3>
                        <p style={styles.infoBitText}>We provide comprehensive electrical verification services to confirm that your fire alarm system is installed correctly and functioning as intended, adhering to all safety standards.</p>
                    </div>

                    {/* Separator */}
                    <div style={isMobile ? styles.horizontalSeparator : styles.verticalSeparator}></div>
                    
                    {/* Maintainance */}
                    <div style={styles.infoBitBox}>
                        <img src={gearsIcon} width={110} alt="Gears icon" />
                        <h3 style={styles.infoBitTitle}>Fire Alarm Maintainance</h3>
                        <p style={styles.infoBitText}>Regular electrical maintenance is key to the longevity and reliability of your fire alarm system. Our maintenance services include electrical inspections, testing, and repairs.</p>
                    </div>
                </div>
                
                {/* Other details */}
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', paddingTop: '2rem', paddingLeft: '1rem', paddingRight: '2rem', maxWidth: '1000px'}}>
                    <h2 style={{color: DURCON_GREEN, textAlign: 'left'}}>Protect Your Property with Expert Electrical Services for Fire Alarms</h2>
                    <h5 style={{textAlign: 'left'}}>Durcon Electric Inc. understands the critical importance of a fully functional fire alarm system. That’s why we offer specialized electrical services to ensure your fire alarms are installed, verified, and maintained with the utmost precision and care.</h5>
                    
                    <br />

                    <h3 style={{color: DURCON_GREEN, textAlign: 'left'}}>Expert Installation</h3>
                    <p style={{textAlign: 'left'}}>Our team of certified electricians is adept at installing the electrical components of fire alarm systems with efficiency and accuracy. We work diligently to minimize disruption and ensure your system is set up for optimal performance.</p>

                    <h3 style={{color: DURCON_GREEN, textAlign: 'left'}}>Thorough Verification</h3>
                    <p style={{textAlign: 'left'}}>Safety is non-negotiable. We meticulously verify every electrical aspect of your fire alarm system to guarantee it meets all safety codes and regulations. Our verification process ensures your system is reliable when it matters most.</p>

                    <h3 style={{color: DURCON_GREEN, textAlign: 'left'}}>Dedicated Maintenance</h3>
                    <p style={{textAlign: 'left'}}>Regular maintenance is key to the longevity of your fire alarm system. Our electricians provide comprehensive maintenance services, including electrical inspections and repairs, to prevent potential issues before they arise.</p>
                </div>

            </div>

            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{maxWidth: '600px'}}>
                    <ContactInline />
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default FireProtectionServicesPage;