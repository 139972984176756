import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MOBILE_BREAKPOINT } from "../constants";
import deIcon from '../images/de.png';
import dfpsIcon from '../images/DFPS.svg';
import { useResponsivePadding } from "../util/responsivePadding";
import useWindowSize from "../util/windowSizeState";
import MenuButton from "./menubutton";
import { getAuth, signOut } from "firebase/auth";

function Header() {
    const [isAuthed, setIsAuthed] = useState(false);
    const [signedOutCheck, setSignedOutCheck] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        setIsAuthed(!!auth.currentUser);
    }, [auth.currentUser]);

    const handleLogout = async () => {
        await signOut(auth);
        setIsAuthed(false);
        setSignedOutCheck(true);
    }

    const headerPadding = useResponsivePadding(0, 5, 15);
    const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;

    const headerContainerStyle: React.CSSProperties = {
        backgroundColor: 'white',
        paddingLeft: headerPadding,
        paddingRight: headerPadding,
        paddingTop: '1rem',
        paddingBottom: '1rem',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 2px 2px 2px #ddd',
    };
    const navLinksContainerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    };
    const navLinkStyle: React.CSSProperties = {
        color: 'black',
        margin: '10px',
    };

    if (isMobile) {


        // Return mobile header
        return (
            <div className="header-container" style={headerContainerStyle}>
                <div className="durcon-logo" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Link to={"/"} className="no-underline">
                        <img src={deIcon} width={50} alt="Durcon Electric Inc." style={{marginLeft: '1rem'}} />
                    </Link>
                </div>
                <div style={{display: 'flex'}}>
                    {
                        isAuthed &&
                        <button onClick={() => handleLogout()} className="btn btn-danger">Log Out</button>
                    }
                    {
                        !isAuthed &&
                        signedOutCheck &&
                        <div className="alert alert-success">Success</div>
                    }
                    <MenuButton isMobile={true} />
                </div>

                <div className="durcon-logo" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Link to={"/dfps"} className="no-underline">
                        <img src={dfpsIcon} width={60} alt="Durcon Fire Protection Services Inc." style={{marginRight: '1rem'}} />
                    </Link>
                </div>
            </div>
        );


    } else {


        // Return desktop header
        return (
            <div className="header-container" style={headerContainerStyle}>
                <div className="durcon-logo" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Link to={"/"} className="no-underline">
                        <img src={deIcon} width={50} alt="Durcon Electric Inc." style={{marginLeft: '1rem'}} />
                    </Link>
                    <Link to={"/dfps"} className="no-underline">
                        <img src={dfpsIcon} width={60} alt="Durcon Fire Protection Services Inc." style={{marginLeft: '1rem'}} />
                    </Link>
                </div>
                <div className="nav-links" style={navLinksContainerStyle}>
                    <Link to={"/services"} className="no-underline">
                        <p style={navLinkStyle}>Services</p>
                    </Link>
                    <Link to={"/contact"} className="no-underline">
                        <p style={navLinkStyle}>Contact</p>
                    </Link>
                    <Link to={"/dfps"} className="no-underline">
                        <p style={navLinkStyle}>Durcon Fire Protection</p>
                    </Link>
                    <Link to={"/about"} className="no-underline">
                        <p style={navLinkStyle}>About Us</p>
                    </Link>

                    {
                        isAuthed &&
                        <button onClick={() => handleLogout()} className="btn btn-danger">Log Out</button>
                    }
                    {
                        !isAuthed &&
                        signedOutCheck &&
                        <div className="alert alert-success">Success</div>
                    }
                    <MenuButton isMobile={false} />
                </div>
            </div>
        );


    }
}

export default Header;