import ServiceButton from "./service_button";

function HomeServices() {
    return (
        <div className="services-container">
            <h1>Services</h1>
            <h4 style={{fontWeight: 'lighter'}}>Click to view more details about a service we offer</h4>
            <div className="services-list">
                <ServiceButton text={"Commercial"} />
                <ServiceButton text={"Industrial"} />
                <ServiceButton text={"Residential"} />
                <ServiceButton text={"Data Cabling & Communication"} />
                <ServiceButton text={"Fire Alarm System Installation"} />
                <ServiceButton text={"Fire Alarm System Verification"} />
            </div>
        </div>
    );
}

export default HomeServices;