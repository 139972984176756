import { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import useWindowSize from "../util/windowSizeState";
import { DURCON_GREEN } from "../constants";
import Dashboard from "./dashboard";

function AdminPage() {
    const [location, setLocation] = useState('');
    const [isAuthed, setIsAuthed] = useState(false);

    const auth = getAuth();

    useEffect(() => {
        document.title = 'Durcon Electric Inc. - Admin';
    }, []);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            setIsAuthed(!!user);
        });

        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition((position) => {
        //         fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`)
        //         .then(response => response.json())
        //         .then(async data => {
        //             const encoder = new TextEncoder();
        //             const encodedData = encoder.encode(data.countryName);
        //             const digestBuffer = await window.crypto.subtle.digest('sha-256', encodedData);
        //             const digestArray = new Uint8Array(digestBuffer);
        //             const digestHex = Array.from(digestArray).map(b => b.toString().padStart(2, '0')).join('');
        //             setLocation(digestHex);
        //         });
        //     });
        // }
    }, []);

    // if (location !== '19085239637678108451564225442511721441731580212222127145991531521192261692029084248') {
    //     return <GeolocationDenied />
    // }

    if (isAuthed) {
        return (<Dashboard />);
    } else {
        return (<AdminLogin />);
    }
}

function AdminDashboard() {
    return (
        <h1>Admin Dashboard</h1>
    );
}

function AdminLogin() {
    const auth = getAuth();

    const width = useWindowSize().width;
    const height = useWindowSize().height;

    const [uname, setUname] = useState('');
    const [pass, setPass] = useState('');
    const [valid, setValid] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        checkValid();
    }, [uname, pass]);

    const editUname = (uname: string) => {
        setUname(uname);
        setError(false);
    }

    const editPass = (pass: string) => {
        setPass(pass);
        setError(false);
    }

    const checkValid = () => {
        if (uname && pass) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    const handleLogin = async () => {
        if (!valid)
            return;

        signInWithEmailAndPassword(auth, uname, pass)
        .catch((reason) => {
            setError(true);
        });
    }

    return (
        <div style={{width: width, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div>
                <label htmlFor="Username" className="form-label" style={{width: '100%', textAlign: 'left'}}>Username</label>
                <input
                    value={uname}
                    onChange={(e => editUname(e.target.value))}
                    type="text"
                    id="Username"
                    className="form-control"
                    placeholder="Username" />
                
                <label htmlFor="Password" className="form-label" style={{width: '100%', textAlign: 'left'}}>Password</label>
                <input
                    value={pass}
                    onChange={(e => editPass(e.target.value))}
                    type="password"
                    id="Password"
                    className="form-control"
                    placeholder="Password" />
                
                <button
                    onClick={handleLogin}
                    className="btn btn-success mt-3"
                    style={
                        {
                            backgroundColor: valid ? DURCON_GREEN : "gray",
                            borderColor: valid ? DURCON_GREEN : "gray",
                            width: '100%'
                        }
                    }
                    >Continue</button>

                <div style={{visibility: error ? "visible" : "hidden"}} className="alert alert-danger mt-3">Invalid username or password</div>
            </div>
        </div>
    );
}

function GeolocationDenied() {
    const height = useWindowSize().height;

    return(
        <div style={{width: '100%', height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p>Access denied.</p>
        </div>
    );
}

export default AdminPage;