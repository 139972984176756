import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyABqqClrk5EjcSttwhJ6OBz2xpW7YuPqaI",
  authDomain: "durconelectric-com.firebaseapp.com",
  projectId: "durconelectric-com",
  storageBucket: "durconelectric-com.appspot.com",
  messagingSenderId: "504829840688",
  appId: "1:504829840688:web:9a41fe20fc4cabfcfb0cf5",
  measurementId: "G-TLHDZ9GH61"
}
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore();
const analytics = getAnalytics(app)
// connectAuthEmulator(auth, 'http://127.0.0.1:9099');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
