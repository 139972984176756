import Footer from "../components/footer";
import Header from "../components/header";
import { DURCON_GREEN, MOBILE_BREAKPOINT } from "../constants";
import useWindowSize from "../util/windowSizeState";
import commercialBackground from '../images/IMG_3840.png';
import industrialBackground from '../images/IMG_1597.png';
import residentialBackground from '../images/IMG_4105.png';
import ContactInline from "../components/contactInline";
import { Link } from "react-router-dom";
import { useEffect } from "react";

// Define the common styles for divs and h2s
const divStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  paddingLeft: '1rem',
  paddingRight: '1rem',
};

const h2Style: React.CSSProperties = {
  color: DURCON_GREEN,
  fontWeight: 'bold',
  width: '800px',
  textAlign: 'left',
};

function ServicesPage() {
  const isMobile = useWindowSize().width < 800;

  useEffect(() => {
    document.title = 'Durcon Electric Inc. - Services';
}, []);

  const styles: {[key: string]: React.CSSProperties} = {
    servicesParent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    serviceContainer: {
      width: isMobile ? '100vw' : '800px',
      backgroundImage: `linear-gradient(to top, rgba(24,79,22,0.9), rgba(0,107,56,0.0))`,
      backgroundSize: 'cover',
      padding: '2rem',
      paddingTop: '10rem',
      marginBottom: '2rem',
    },
    serviceTextbox: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      padding: '1rem',
    },
    serviceTitle: {
      color: 'white',
      fontWeight: 'bold',
    },
    serviceDescription: {
      color: 'white',
    },
    secondaryContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    secondaryTitle: {
      width: isMobile ? '100%' : '800px',
      textAlign: "left",
    },
    secondaryDescription: {
      width: isMobile ? '100%' : '800px',
      textAlign: "left",
    },
    viewMoreButton: {
      backgroundColor: '#3C7E83',
      color: 'white',
      width: '16rem',
    }
  }

  return (
    <div>
      <Header />
      <div style={{...divStyle, paddingTop: '3rem'}}> {/* Use the common div style */}
        <h1 style={{...h2Style, paddingLeft: '0rem'}}>Explore Our Services</h1> {/* Use the common h2 style */}
      </div>
      <div style={{...divStyle, paddingLeft: '3rem', paddingRight: '3rem'}}> {/* Use the common div style */}
        <p style={{maxWidth: MOBILE_BREAKPOINT * 1.5}}><i>At Durcon Electric Inc., we specialize in a comprehensive range of electrical services designed for the commercial and industrial sectors. From intricate electrical installations to routine maintenance and emergency repairs, our services are crafted to ensure your operations run smoothly and safely. Dive into our service offerings to discover how we can enhance your electrical infrastructure and support your business' growth.</i></p>
      </div>
      <div style={divStyle}> {/* Use the common div style */}
        <h2 style={{...h2Style, paddingLeft: '1rem'}}>Services</h2> {/* Use the common h2 style */}
      </div>
      <div style={styles.servicesParent}>
        <div style={{...styles.serviceContainer, backgroundImage: `url(${commercialBackground})`, backgroundPosition: 'bottom'}}> {/* Commercial container */}
          <div style={styles.serviceTextbox}> {/* Commercial textbox */}
            <h2 style={styles.serviceTitle}>Commercial</h2>
            <p style={styles.serviceDescription}>Our commercial services focus on delivering efficient and reliable safety systems to protect your business and its valuable assets. We understand the unique needs of commercial spaces and offer tailored solutions to ensure a safe and secure business environment.</p>
          </div>
        </div>
        <div style={{...styles.serviceContainer, backgroundImage: `url(${industrialBackground})`, backgroundPosition: 'center'}}> {/* Industrial container */}
          <div style={styles.serviceTextbox}> {/* Industrial textbox */}
            <h2 style={styles.serviceTitle}>Industrial</h2>
            <p style={styles.serviceDescription}>We offer robust and customized safety measures designed to meet the unique and often complex needs of industrial environments. Our industrial services aim to enhance workplace safety, ensuring the protection of both your workforce and industrial assets.</p>
          </div>
        </div>
        <div style={{...styles.serviceContainer, backgroundImage: `url(${residentialBackground})`, backgroundPosition: 'center'}}> {/* Residential container */}
          <div style={styles.serviceTextbox}> {/* Residential textbox */}
            <h2 style={styles.serviceTitle}>Residential</h2>
            <p style={styles.serviceDescription}>We specialize in providing comprehensive safety solutions for your home. Our residential services are designed to ensure the utmost safety and peace of mind for your family. We use state-of-the-art technology to protect your home and loved ones from potential hazards.</p>
          </div>
        </div>
      </div>
      <div style={{padding: '1rem'}}>
        <div style={styles.secondaryContainer}> {/* Data cabling & communication */}
          <h3 style={styles.secondaryTitle}>Data Cabling<br />& Communication</h3>
          <p style={styles.secondaryDescription}>Our Data Cabling & Communication service ensures seamless and efficient data transfer within your infrastructure. We provide high-quality cabling solutions tailored to your specific needs, ensuring reliable and secure communication.</p>
        </div>
        <div style={divStyle}> {/* Use the common div style */}
          <hr style={{width: isMobile ? '100%' : '800px'}} />
        </div>
        <div style={styles.secondaryContainer}> {/* Fire alarm system installation */}
          <h3 style={styles.secondaryTitle}>Fire Alarm System<br />Installation</h3>
          <p style={styles.secondaryDescription}>We offer professional Fire Alarm System Installation services. Our team of experts will design and install a comprehensive fire alarm system tailored to your property’s layout, ensuring maximum safety and quick response in case of emergencies.</p>
          <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', width: isMobile ? '100%' : '800px'}}>
            
            <Link to={"/dfps"} className="no-underline">
                <p className='btn btn-lg' style={styles.viewMoreButton}>View More</p>
            </Link>

          </div>
        </div>
        <div style={divStyle}> {/* Use the common div style */}
          <hr style={{width: isMobile ? '100%' : '800px'}} />
        </div>
        <div style={styles.secondaryContainer}> {/* Fire alarm system verification */}
          <h3 style={styles.secondaryTitle}>Fire Alarm System<br />Verification</h3>
          <p style={styles.secondaryDescription}>Our Fire Alarm System Verification service ensures that your fire alarm system is functioning correctly and meets all safety standards. We conduct thorough inspections and tests to verify system performance, providing you with peace of mind knowing your premises are well-protected.</p>
          <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', width: isMobile ? '100%' : '800px'}}>
            
            <Link to={"/dfps"} className="no-underline">
                <p className='btn btn-lg' style={styles.viewMoreButton}>View More</p>
            </Link>

          </div>
        </div>
      </div>
      
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{maxWidth: '600px'}}>
          <ContactInline />
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default ServicesPage;
