import { useState, useEffect } from "react";

const BREAK_WIDE = 1000;
const BREAK_MOBILE = 500;

export function useResponsivePadding(mobilePercent: number, mediumPercent: number, widePercent: number) {
    const [padding, setPadding] = useState('0');

    useEffect(() => {
        function handleResize() {
            const width = window.innerWidth;
            if (width > BREAK_WIDE) {
                setPadding(`${widePercent}%`);
            } else if (width < BREAK_MOBILE) {
                setPadding(`${mediumPercent}%`);
            } else {
                setPadding(`${mobilePercent}%`);
            }
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    return padding;
}