import { Link } from "react-router-dom";

type ServiceButtonProps = {
    text: string;
};

function ServiceButton({ text }: ServiceButtonProps) {
    return (
        <Link to={"/services"} className="no-underline">
            <div className="service-button">
                <h3 style={{
                    fontSize: '22px',
                    fontWeight: '400',
                    padding: '0',
                    margin: '0',
                    color: 'black',
                    }}>{text}</h3>
            </div>
        </Link>
    );
}

export default ServiceButton;