import { DURCON_GREEN } from '../constants';
import assocCfaa from '../images/cfaa_logo.png';
import assocEsa from '../images/esa_logo.jpeg';
import assocOel from '../images/oel_logo.jpg';

function HomeAssociations() {
    return (
        <>
            <div>
                <h2 style={{color: DURCON_GREEN, fontWeight: 'bold', fontSize: 'xx-large'}}>Associations</h2>
            </div>
            <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingBottom: '3rem',
                    }}>
                <a href="https://www.cfaa.ca" target='_blank'>
                    <img src={assocCfaa} height={100} alt="Canadian Fire Alarm Association" />
                </a>
                <a href="https://esasafe.com" target='_blank'>
                    <img src={assocEsa} height={150} alt="Electrical Safety Authority" />
                </a>
                <a href="https://www.oel.org" target='_blank'>
                    <img src={assocOel} height={100} alt="Ontario Electric League" />
                </a>
            </div>
        </>
    );
}

export default HomeAssociations;