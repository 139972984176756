import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/home';
import ServicesPage from './pages/services';
import ContactPage from './pages/contact';
import FireProtectionServicesPage from './pages/dfps';
import ESAPage from './pages/esa';
import GalleryPage from './pages/gallery';
import AdminPage from './pages/admn';
import AboutPage from './pages/about';
import ScrollToTop from './util/scrollToTop';

function App() {
  return (
    <div className='App'>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/dfps' element={<FireProtectionServicesPage />} />
          <Route path='/esa' element={<ESAPage />} />
          <Route path='/gallery' element={<GalleryPage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/admn' element={<AdminPage />} />
          <Route path='*' element={<Navigate to={'/'} />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;