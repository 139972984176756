import React, { MouseEventHandler, useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { DURCON_GREEN, MOBILE_BREAKPOINT } from "../constants";
import useWindowSize from "../util/windowSizeState";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import arrowLeft from '../images/arrowl.png';
import arrowRight from '../images/arrowr.png';

function GalleryPage() {
    const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;
    const [thumbnailUrls, setThumbnailUrls] = useState<string[]>([]); // Separate state for thumbnails
    const [originalUrls, setOriginalUrls] = useState<string[]>([]); // Separate state for original images
    const storage = getStorage();

    useEffect(() => {
        document.title = 'Durcon Electric Inc. - Gallery';

        const storageRefThumbnails = ref(storage, 'thumbnails');
        const storageRefOriginals = ref(storage);

        // Fetch thumbnail URLs
        listAll(storageRefThumbnails)
            .then(async (res) => {
                const promises = res.items.map((item) => getDownloadURL(item));
                const _thumbnails = await Promise.all(promises);
                setThumbnailUrls(_thumbnails);
            })
            .catch((error) => {
                console.error('Error fetching thumbnail URLs:', error);
            });

        // Fetch original image URLs
        listAll(storageRefOriginals)
            .then(async (res) => {
                const promises = res.items.map((item) => getDownloadURL(item));
                const _originals = await Promise.all(promises);
                setOriginalUrls(_originals);
            })
            .catch((error) => {
                console.error('Error fetching original image URLs:', error);
            });
    }, [storage]);

    const styles: {[key: string]: React.CSSProperties} = {
        mainPageContent: {

        },
        pageTitle: {
            color: DURCON_GREEN,
            fontWeight: 'bold',
        },
    };

    return (
        <div>
            <Header />

            {/* Main page content */}
            <div style={{marginTop: '1rem'}}>
                {/* Page title */}
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <h1 style={styles.pageTitle}>Image Gallery</h1>
                </div>

                {/* Carousel */}
                {/* <Carousel images={originalUrls} /> */}

                <h3 style={{marginTop: '3rem', fontWeight: '400'}}>Click to view full-sized images</h3>

                {/* Image grids */}
                <ImageGrid thumbnails={thumbnailUrls} originals={originalUrls} />

            </div>

            <Footer />
        </div>
    );
}

function Carousel({ images }: { images: string[] }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [loadedStates, setLoadedStates] = useState(new Array(images.length).fill(false));

    const prevSlide = () => {
        const index = (currentImageIndex - 1 + images.length) % images.length;
        setCurrentImageIndex(index);
    }
    const nextSlide = () => {
        const index = (currentImageIndex + 1) % images.length;
        setCurrentImageIndex(index);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            nextSlide();
        }, 6000);

        return () => clearTimeout(timer);
    }, [currentImageIndex, loadedStates]);

    const handleImageLoad = (index: number) => {
        setLoadedStates(prevLoadedStates => {
            const newLoadedStates = [...prevLoadedStates];
            newLoadedStates[index] = true;
            console.log(newLoadedStates);
            return newLoadedStates;
        });
    }

    const styles: {[key: string]: React.CSSProperties} = {
        parent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        container: {
            position: 'relative',
            width: '50vh',
            height: '50vh',
        },
        image: {
            objectFit: 'contain',
            maxWidth: '100%',
            maxHeight: '100%',
            boxShadow: '3px 3px 10px 2px gray',
            borderRadius: '10px',

            transition: 'opacity 1s ease-in-out',
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
        },
        button: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            border: 'none',
            background: 'rgba(0,0,0,0)',
            cursor: 'pointer',
        },
        prevButton: {
            left: '0',
            zIndex: 1,
        },
        nextButton: {
            right: '0',
            zIndex: 1,
        }
    }

    return (
        <div style={styles.parent}>
            {loadedStates[0] && (
                <div style={styles.container}>
                    <button style={{ ...styles.button, ...styles.prevButton }} onClick={prevSlide}>
                        <img src={arrowLeft} width={50} alt="Left arrow" />
                    </button>

                    {images.map((img, index) => (
                        <img
                            style={{
                                ...styles.image,
                                opacity: currentImageIndex === index ? 1 : 0,
                            }}
                            src={img}
                            alt="Carousel slide"
                            key={index}
                            onLoad={() => handleImageLoad(index)}
                        />
                    ))}

                    <button style={{ ...styles.button, ...styles.nextButton }} onClick={nextSlide}>
                        <img src={arrowRight} width={50} alt="Right arrow" />
                    </button>
                </div>
            )}
        </div>
    );
}

function ImageGrid({thumbnails, originals}: {thumbnails: string[], originals: string[]}) {
    const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;

    const styles: {[key: string]: React.CSSProperties} = {
        tileContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '2rem',
            paddingRight: '2rem',
        },
    }

    return (
        <div style={styles.tileContainer}>

            {thumbnails.map((thumbnail, index) => (
                <ImageTile key={index} thumbnail={thumbnail} original={originals[index]} isMobile={isMobile} />
            ))}

        </div>
    );
}

function ImageTile({thumbnail, original, isMobile}: {thumbnail: string, original: string, isMobile: boolean}) {
    const IMAGE_SIZE = isMobile ? '30vw' : '200px';

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpen(false);
    };

    const styles: {[key: string]: React.CSSProperties} = {
        tile: {
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
            margin: '1rem',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '20px',
        }
    }

    return (
        <div style={styles.tile} onClick={handleOpen}>
            <img style={styles.image} src={thumbnail} alt="Gallery tile" />
            {isOpen && <Modal image={original} onClose={handleClose} />}
        </div>
    );
}

function Modal({image, onClose}: {image: string, onClose: MouseEventHandler}) {

    const styles: {[key: string]: React.CSSProperties} = {
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '10',
        },
        image: {
            maxWidth: '90%',
            maxHeight: '90%',
        }
    }

    return (
        <div style={styles.modal} onClick={onClose}>
            <img style={styles.image} src={image} alt="Fullscreen modal" />
        </div>
    );
}

export default GalleryPage;