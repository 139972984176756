import { MOBILE_BREAKPOINT } from '../constants';
import heroImage from '../images/IMG_3904.png';
import useWindowSize from '../util/windowSizeState';

function HomeHero() {
    const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;

    return (
        <div style={{
            height: isMobile ? '30vh' : '50vh',
        }}>
            <div className="hero-container">
                <div className="overlay-container">
                    <img className="background-image" src={heroImage} alt="" />
                    <div className="dark-overlay"></div>
                    <div className="overlay-text">
                        <p style={{fontWeight: 'bold', margin: '0', padding: '0'}}>Durcon Electric Inc.<br />& Durcon Fire Protection Services Inc.</p>
                        <p style={{fontSize:'medium', margin:'0', padding:'0'}}>Serving the Greater Toronto Area Since 1995</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeHero;