import React from "react";
import { Link } from "react-router-dom";
import { DURCON_GREEN } from "../constants";
import deWhiteIcon from '../images/dew.png';
import dfpsIcon from '../images/DFPS_w.svg';

function Footer() {
    const headerContainerStyle: React.CSSProperties = {
        backgroundColor: DURCON_GREEN,
        padding: '3rem',
        marginTop: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };
    const footerTextStyle: React.CSSProperties = {
        color: 'white',
    }

    return (
        <div className="footer-container" style={headerContainerStyle}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Link to={'/services'} style={{textDecorationColor:"white"}}>
                        <p style={{color: "white"}}>Services</p>
                    </Link>
                    <Link to={'/contact'} style={{textDecorationColor:"white"}}>
                        <p style={{color: "white"}}>Contact</p>
                    </Link>
                    <Link to={'/esa'} style={{textDecorationColor:"white"}}>
                        <p style={{color: "white"}}>ESA</p>
                    </Link>
                    <Link to={'/gallery'} style={{textDecorationColor:"white"}}>
                        <p style={{color: "white"}}>Gallery</p>
                    </Link>
                    <Link to={'/aboutUs'} style={{textDecorationColor:"white"}}>
                        <p style={{color: "white"}}>About Us</p>
                    </Link>
                    <Link to={'/esa'} style={{textDecorationColor:"white"}}>
                        <p style={{color: "white"}}>ECRA/ESA License #7004995</p>
                    </Link>
                    <Link to={'/dfps'} style={{textDecorationColor:"white"}}>
                        <p style={{color: "white"}}>Durcon Fire Protection Services Inc.</p>
                    </Link>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <a href="tel:+19057611834" style={{color: 'white', fontWeight: "bold"}}>905-761-1834</a>
                    <a href="mailto:info@durconelectric.com" style={{color: 'white', fontWeight: "bold"}}>info@durconelectric.com</a>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{maxWidth:'50px', maxHeight:'50px', margin: '1rem'}}>
                    <img src={deWhiteIcon} width={50} alt="Durcon Electric Inc." />
                </div>
                <div style={{maxWidth:'50px', maxHeight:'60px', margin: '1rem'}}>
                    <img src={dfpsIcon} width={60} alt="Durcon Fire Protection Services Inc." />
                </div>
            </div>
            <p style={{color: "white"}}>© Durcon Electric Inc. All rights reserved</p>
        </div>
    );
}

export default Footer;