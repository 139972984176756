import React, { useEffect, ReactNode } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import HomeHero from "../components/home_hero";
import HomeServices from "../components/home_services";
import ContactInline from "../components/contactInline";
import ClientsTestimonials from "../components/clients_testimonials";
import HomeMediaGallery from "../components/home_mediagallery";
import HomeFAQ from "../components/home_faq";
import HomeAssociations from "../components/home_associations";


interface CenteredDivProps {
    children: ReactNode;
}

const CenteredDiv: React.FC<CenteredDivProps> = ({children}) => (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        {children}
    </div>
);

function HomePage() {
    useEffect(() => {
        document.title = 'Durcon Electric Inc. - Home';
    }, []);

    return (
        <div className="home-page" style={{display: 'flex', flexDirection: 'column'}}>
            <Header />
            <HomeHero />
            <HomeServices />
            <HomeAssociations />
            <CenteredDiv>
                <div style={{maxWidth: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ContactInline />
                </div>
            </CenteredDiv>
            {/* <CenteredDiv>
                <ClientsTestimonials />
            </CenteredDiv> */}
            <CenteredDiv>
                <HomeMediaGallery />
            </CenteredDiv>
            {/* <CenteredDiv>
                <HomeFAQ />
            </CenteredDiv> */}
            <Footer />
        </div>
    );
}

export default HomePage;
