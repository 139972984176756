import React, { ChangeEvent, useEffect, useState } from "react";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { DURCON_GREEN, MAX_FILE_SIZE, MOBILE_BREAKPOINT } from "../constants";
import useWindowSize from "../util/windowSizeState";

function AdminGalleryManagement() {
    const [images, setImages] = useState<string[]>([]);
    const storage = getStorage();

    const [file, setFile] = useState<File | null>(null);
    const [fileTooLarge, setFileTooLarge] = useState(false);

    const handeFileSelection = (e: ChangeEvent<HTMLInputElement>) => {
        setFileTooLarge(false);
        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            if (!file.type.startsWith('image/')) {
                console.error('Invalid file type selected');
            }
            // if (file.size > MAX_FILE_SIZE) {
            //     console.error('Image too large');
            //     setFileTooLarge(true);
            // }
            setFile(file);
        }
    }

    const handleUpload = () => {
        if (!file) return;

        if (fileTooLarge)
            return;
        
        if (!file.type.startsWith('image/'))
            return;

        const storageRef = ref(storage, `${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {

            },
            (error) => {
                console.log(error)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setImages(prevImages => [...prevImages, downloadURL]);
                });
            }
        );
    }

    useEffect(() => {
        const storageRef = ref(storage);
        listAll(storageRef)
        .then((res) => {
            return Promise.all(res.items.map(itemRef => getDownloadURL(itemRef)));
        })
        .then(urls => {
            setImages(urls)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [storage]);

    return (
        <div>
            <h2 style={{textAlign: 'left', padding: '1rem'}}>Photo Gallery Manager</h2>

            {/* Component to upload new image */}
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: '500px', margin: '1rem', padding: '1rem', boxShadow: '2px 2px 10px 1px #ccc', borderRadius: '10px'}}>
                {/* File input */}
                <input type="file" onChange={handeFileSelection} className="form-control" />
                <small style={{color: fileTooLarge ? 'red' : 'black', textAlign: 'left'}}>Maximum file size of 2MB</small>
                {/* Submit button */}
                <button onClick={handleUpload} className="btn btn-success mt-3" style={{maxWidth: '60%', borderColor: 'gray', backgroundColor: fileTooLarge ? 'gray' : DURCON_GREEN}}>Upload new image</button>
            </div>

            {/* Image gallery preview */}
            <ImageGallery images={images} setImages={setImages} />
        </div>
    );
}

const ImageGallery = ({images, setImages}: {images: string[], setImages: React.Dispatch<React.SetStateAction<string[]>>}) => {
    const storage = getStorage();
    const isMobile = useWindowSize().width < MOBILE_BREAKPOINT;
    const [viewImage, setViewImage] = useState<string | null>(null);

    const openModal = (url: string) => {
        setViewImage(url);
    }

    const closeModal = () => {
        setViewImage(null);
    }

    const handleDelete = (url: string) => {
        if (window.confirm('Are you sure that you want to delete this image?')) {
            const imageRef = ref(storage, url);
            deleteObject(imageRef).then(() => {
                setImages(images.filter(image => image !== url));
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }
    
    const tileSize = isMobile ? '40vw' : '20vw';

    const styles: {[key: string]: React.CSSProperties} = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
        },
        imageTile: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: tileSize,
            maxHeight: tileSize,
            margin: '1rem',
            marginBottom: '5rem',
            borderRadius: '10px',
        },
        image: {
            maxWidth: tileSize,
            maxHeight: tileSize,
            marginBottom: '5px',
            borderRadius: '10px',
            objectFit: 'cover',
            objectPosition: 'center',
        },
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            zIndex: 1,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            backgroundColor: 'rgba(0,0,0,0.4)',
        },
        modalContent: {
            maxWidth: '95%',
            maxHeight: '95%',
        },
    }

    return (
        <div style={styles.container}>
            {images.map((url, index) => (
            <div key={index} style={styles.imageTile}>
                <img style={styles.image} src={url} alt={`img-${index}`} onClick={() => openModal(url)} />
                <button className="btn btn-danger" onClick={() => handleDelete(url)}>Delete</button>
            </div>
        ))}

        {viewImage && (
            <div style={styles.modal} onClick={closeModal}>
                <img style={styles.modalContent} src={viewImage} />
            </div>
        )}
        </div>
    );
};

export default AdminGalleryManagement;