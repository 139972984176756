import React, { useEffect, useState } from "react";
import { DURCON_GREEN } from "../constants";
import phoneIcon from '../images/phone-call.png';
import emailIcon from '../images/email.png';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

// Define your styles here
const styles: {[key: string]: React.CSSProperties} = {
  container: {
    margin: '2rem',
    borderRadius: '20px 20px 20px 20px',
    boxShadow: '0px 2px 10px 2px #ddd',
    transform: '',
  },
  title: {
    backgroundColor: DURCON_GREEN,
    padding: '20px',
    borderRadius: '20px 20px 0px 0px',
    display: "flex"
  },
  h4: {
    color: "white",
    fontSize: '1.25rem',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  contentContainer: {
    padding: '1rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: "wrap",
    justifyContent: "center",
  },
  inputFields: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    maxWidth: '80%'
  },
  button: {
    backgroundColor: DURCON_GREEN,
    width: '100%',
  },
  separator: {
    width: '5vw'
  },
  contactInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    padding: '2rem',
  },
  h3: {
    fontWeight: "bold"
  },
  link: {
    textDecorationColor: "black"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  p: {
    margin: "auto",
    marginLeft: '1rem',
    color: "black"
  },
  separatorDiv: {
    height: '10px'
  }
};

function ContactInline() {
    const SCALE_FACTOR = 1.0;
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
      firstname: '',
      email: '',
      message: '',
    });

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
      e.preventDefault()

      // Validate form data before submitting
    if (!formData.firstname || !formData.email || !formData.message) {
        alert('All fields must be filled out before submitting.');
        return;
    }

      const firestore = getFirestore();

      try {
        const docRef = await addDoc(collection(firestore, 'contact-submissions'), {
            firstname: formData.firstname,
            email: formData.email,
            message: formData.message,
            timestamp: serverTimestamp(),
        });
          setFormData({
            firstname: '',
            email: '',
            message: '',
          });
          setSubmitted(true);
        } catch (e) {
          // console.log(e);
        }
    }

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener
      return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array ensures this effect runs once on mount and cleanup on unmount
  
    // Add dynamic styles here
    const dynamicStyles = {
      container: {
        ...styles.container,
        transform: `scale(${SCALE_FACTOR})`,
      },
      inputFields: {
        ...styles.inputFields,
        minWidth: windowSize.width > 800 ? '300px' : '0px',
      },
      input: {
        ...styles.input,
        maxWidth: windowSize.width > 800 ? '80%' : '100%',
      },
    };
  
    if (!submitted) {
      return (
        // Container
        <div style={dynamicStyles.container}>
          
          {/* Title */}
          <div style={styles.title}>
            <h4 style={styles.h4}>Questions? Get In Touch</h4>
          </div>
          
          {/* Content container */}
          <div style={styles.contentContainer}>
            
            {/* Input fields */}
            <div style={dynamicStyles.inputFields}>
              
              <div className="input-group mb-3">
                <input type="text" className="form-control" name="firstname" onChange={handleChange} placeholder="Your Name" aria-label="Your Name" style={dynamicStyles.input} />
              </div>
              <div className="input-group mb-3">
                <input type="text" className="form-control" name="email" onChange={handleChange} placeholder="Your Email Address" aria-label="Your Email Address" style={dynamicStyles.input} />
              </div>
              <div className="input-group mb-3">
                <textarea className="form-control" name="message" onChange={handleChange} cols={30} rows={5} placeholder="Email body"></textarea>
              </div>
              <button className="btn btn-success" onClick={handleSubmit} style={styles.button}>Send</button>
            
            </div>

            <hr style={{width: '100%'}} />
            
            {/* Contact Info */}
            <div style={styles.contactInfo}>
              
              <h3 style={styles.h3}>Contact</h3>
              <p style={{fontWeight: 'lighter'}}>We also encourage you to contact us directly at:</p>
              
              {/* Phone number */}
              <a href="tel:+19057611834" style={styles.link}>
                <div style={styles.flexCenter}>
                  <img src={phoneIcon} width={'25px'} alt="Phone icon" />
                  <p style={styles.p}>905-761-1834</p>
                </div>
              </a>
              
              {/* Separator */}
              <div style={styles.separatorDiv}></div>
              
              {/* Email address */}
              <a href="mailto:info@durconelectric.com" style={styles.link}>
                
                <div style={styles.flexCenter}>
                  <img src={emailIcon} width={'25px'} alt="Phone icon" />
                  <p style={styles.p}>info@durconelectric.com</p>
                </div>
                
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return <SuccessComponent />;
    }
}

function SuccessComponent() {
  return (
    <p className="alert alrt-success">Message successfully sent</p>
  );
}
  

export default ContactInline;
