import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import { DURCON_GREEN } from "../constants";
import ContactInline from "../components/contactInline";

function AboutPage() {

    const styles: {[key: string]: React.CSSProperties} = {
        p: {
            textAlign: 'left',
        },
        h2: {
            color: DURCON_GREEN,
        }
    }

    return (
        <div>
            <Header />

            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
                <h1 style={{color:DURCON_GREEN, fontWeight:'bold', marginTop:'2rem'}}>About Us</h1>

                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start', paddingLeft: '3rem', paddingRight: '3rem', maxWidth:'800px'}}>
                    
                    <h2 style={styles.h2}>Who we are</h2>
                    <p style={styles.p}>Durcon Electric Inc. is an electrical contracting company based in Concord, Ontario. Established in 1995, we have over three decades of experience in providing top-notch electrical services to both commercial and industrial clients. Our team of highly skilled and certified electricians is dedicated to delivering efficient, reliable, and safe electrical solutions. We take pride in our commitment to quality, safety, and customer satisfaction. At Durcon Electric, we power your businesses, ensuring that your electrical systems are up to date, safe, and efficient.</p>
                    <Divider />

                    <h2 style={styles.h2}>Mission statement</h2>
                    <p style={styles.p}>Our mission is to provide superior electrical contracting services by demonstrating the highest levels of professionalism, integrity, and expertise. We are dedicated to enhancing the success of our clients by delivering innovative solutions and exceptional service in every project. Our commitment to safety, sustainability, and continuous improvement sets us apart in the industry. We strive to establish long-lasting relationships with our clients based on trust and mutual respect, and we pledge to exceed expectations in every facet of our work</p>
                    <Divider />

                    <h2 style={styles.h2}>What we offer</h2>
                    <p style={styles.p}>We specialize in a wide range of offerings designed to meet all your electrical needs. From commercial electrical installations, to industrial maintenance, residential wiring and emergency repairs.</p>
                    <Link to={"/services"}>
                        <button className="btn btn-success" style={{backgroundColor: DURCON_GREEN}}>View comprehensive service list</button>
                    </Link>
                    <Divider />

                    <h2 style={styles.h2}>Licenses and certifications</h2>
                    <p style={styles.p}>We pride ourselves on our extensive portfolio of licenses and certifications. Our team of professionals is not only highly skilled, but also rigorously trained and certified to meet industry standards. We hold a variety of licenses that allow us to perform a wide range of electrical services, ensuring we’re equipped to handle any project, big or small.</p>
                    <Link to={"/esa"}>
                        <button className="btn btn-success" style={{backgroundColor: DURCON_GREEN}}>More about ESA certifications</button>
                    </Link>
                    
                    <div style={{height:'5rem'}}></div>
                    <h2 style={styles.h2}>Feel free to contact us if you have any questions</h2>
                </div>
            </div>
            
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{maxWidth: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ContactInline />
                </div>
            </div>

            <Footer />
        </div>
    );
}

function Divider() {
    const styles: {[key: string]: React.CSSProperties} = {
        hr: {
            width: '100%',
            height: '3rem',
            borderWidth: '3px',
            color: DURCON_GREEN,
        }
    }

    return (
        <hr style={styles.hr} />
    );
}

export default AboutPage;