import { Link } from "react-router-dom";
import { DURCON_GREEN } from "../constants";

type MenuButtonProps = {
    isMobile: boolean;
}

function MenuButton({isMobile}: MenuButtonProps) {
    if (isMobile) {


        // Return mobile menu button
        return (
            <div className="dropdown">
                <button className="btn btn-success dropdown-toggle mx-3" style={{backgroundColor: DURCON_GREEN}} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Menu
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><Link className="dropdown-item" to="/services">Services</Link></li>
                    <li><Link className="dropdown-item" to="/contact">Contact</Link></li>
                    <li><Link className="dropdown-item" to="/dfps">Fire Protection Services Inc.</Link></li>
                    <li><Link className="dropdown-item" to="/about">About Us</Link></li>
                    
                    <li><hr className="dropdown-divider" /></li>

                    <li><Link className="dropdown-item" to="/esa">ESA Information</Link></li>
                    <li><Link className="dropdown-item" to="/gallery">Image Gallery</Link></li>
                    
                    <li><hr className="dropdown-divider" /></li>
                    
                    <li><a href="tel:+19057611834" className="dropdown-item" style={{color:DURCON_GREEN, fontWeight:'600'}}>Phone: 905-761-1834</a></li>
                    <li><a href="mailto:info@durconelectric.com" className="dropdown-item" style={{color:DURCON_GREEN, fontWeight:'600'}}>info@durconelectric.com</a></li>
                </ul>
            </div>
        );

        
    } else {


        // Return desktop menu button
        return (
            <div className="dropdown">
                <button className="btn btn-success dropdown-toggle mx-3" style={{backgroundColor: DURCON_GREEN}} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Menu
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><Link className="dropdown-item" to="/esa">ESA Information</Link></li>
                    <li><Link className="dropdown-item" to="/gallery">Image Gallery</Link></li>
                    <li><hr className="dropdown-divider" /></li>

                    <li><a href="tel:+19057611834" className="dropdown-item" style={{color:DURCON_GREEN, fontWeight:'600'}}>Phone: 905-761-1834</a></li>
                    <li><a href="mailto:info@durconelectric.com" className="dropdown-item" style={{color:DURCON_GREEN, fontWeight:'600'}}>info@durconelectric.com</a></li>
                </ul>
            </div>
        );


    }
}

export default MenuButton;