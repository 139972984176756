import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function AdminAccount() {
    const auth = getAuth();

    const handlePasswordReset = () => {
        // sendPasswordResetEmail(auth, auth.currentUser!.email!)
    }

    return (
        <div style={{textAlign: 'left'}}>
            <h2>Account</h2>
            <button onClick={handlePasswordReset} className="btn btn-danger">Reset Account Password</button>
        </div>
    );
}

export default AdminAccount;